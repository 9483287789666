import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authSlice: AuthSliceFacade) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('/api')) return next.handle(request);

        return this.authSlice.authToken$.pipe(
            filter(Boolean),
            switchMap((token) => {
                return next.handle(
                    request.clone({
                        setHeaders: {
                            ['Authorization']: `Bearer ${token}`
                        }
                    })
                );
            })
        );
    }
}
