export const environment = {
    production: true,
    buildNumber: '#{Env:buildNumber}',
    brand: {
        name: '#{Env:brandName}',
        displayName: '#{Env:brandDisplayName}',
        assetsUrl: '#{Env:brandAssetsUrl}',
        aboutUsUrl: '#{Env:brandAboutUsUrl}'
    },
    baseUrl: '#{Env:baseUrl}',
    auth0: {
        clientId: '#{Env:auth0ClientId}',
        domain: '#{Env:auth0Domain}',
        audience: '#{Env:auth0Audience}',
        redirectUri: '#{Env:auth0RedirectUri}',
        returnTo: '#{Env:auth0ReturnTo}'
    },
    appInsights: {
        instrumentationKey: '#{Env:appInsightsInstrumentationKey}'
    },
    socketUrl: '#{Env:socketUrl}'
};
