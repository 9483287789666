import { AuthService } from '@kno2/desktop/data-access/api-desktop';
import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInsightsService, IntercomService } from '@kno2/shared/util/integrations';
import { BehaviorSubject, Subscription, of, combineLatest, from } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { ThemeHotloaderService } from '@kno2/shared/ui/theme';
import {
    ProfileApiFacade,
    FeaturesApiFacade,
    IntegrationsApiFacade,
    OrganizationSummaryResource,
    ReleaseTypesApiFacade
} from '@kno2/shared/data-access/api-kno2fy-main';
import { APP_ORIGIN_NAME_TOKEN } from '@kno2/shared/util/configuration';

@Component({
    selector: 'kno2-app',
    template: `
        <div *ngIf="(appReady$ | async) || (userReady$ | async); else loading">
            <ng-container *ngTemplateOutlet="template"></ng-container>
        </div>
        <ng-template #template>
            <router-outlet></router-outlet>
        </ng-template>
        <ng-template #loading>
            <!-- Use bootstrap spinner in case nbSpinner is not yet loaded -->
            <div
                class="d-flex justify-content-center align-items-center"
                style="height: 100vh;">
                <div
                    class="spinner-border text-primary"
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    `
})
export class AppComponent implements OnInit, OnDestroy {
    public appReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public userReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private subscriptions: Array<Subscription> = [];

    constructor(
        private appInsights: ApplicationInsightsService,
        private intercomService: IntercomService,
        private themeHotLoaderService: ThemeHotloaderService,
        private profileApiFacade: ProfileApiFacade,
        private featuresApiFacade: FeaturesApiFacade,
        private integrationsApiFacade: IntegrationsApiFacade,
        private authSlice: AuthSliceFacade,
        private authService: AuthService,
        private releaseTypesApi: ReleaseTypesApiFacade,
        @Inject(APP_ORIGIN_NAME_TOKEN) private appName: string
    ) {}

    public ngOnInit(): void {
        const themeSub = this.themeHotLoaderService.reloadTheme().subscribe((x) => {
            this.themeHotLoaderService.configure();
        });

        const profileInitializationSub = this.authSlice.authToken$
            .pipe(
                filter((token) => !!token),
                switchMap((existingToken) =>
                    combineLatest([this.profileApiFacade.getMergedProfileData$(), this.profileApiFacade.getActiveOrganziation$(), of(existingToken)])
                ),
                tap((data) => {
                    // ensure that appReady only emits once
                    if (!this.appReady$.value && !data) this.appReady$.next(true);
                })
            )
            .subscribe(([user, activeOrganization, token]) => {
                this.initializeIntegrations(user, token, activeOrganization);
                this.initializeFeatures(user);
                this.initializeCapabilities();
                this.intializeReleaseTypes();

                this.userReady$.next(true);
            });

        const authToken = this.authService.accessToken$().subscribe((token) => {
            this.authSlice.setAuthToken(token);
        });

        this.subscriptions.push(profileInitializationSub, themeSub, authToken);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    private initializeIntegrations(user: any, authToken: string, organization: OrganizationSummaryResource): void {
        this.appInsights.setUserContext(user.userId, user.organizationId);
        this.initializeIntercom(user);
    }

    private initializeIntercom(user: any): void {
        from(window.api.getDebugInfo())
            .pipe(
                take(1),
                tap(({ payload }) => {
                    this.intercomService.boot(user.intercomAppId, user.userId, {
                        Application: this.appName,
                        'Session ID': payload.sessionId,
                        'App Version': payload.appVersion,
                        'Auto Update Enabled': payload.isAutoUpdateEnabled ? 'yes' : 'no'
                    });
                })
            )
            .subscribe();
    }

    private initializeFeatures(user: any): void {
        this.subscriptions.push(this.featuresApiFacade.getEnabledFeatures$.subscribe());
    }

    private initializeCapabilities(): void {
        this.subscriptions.push(this.integrationsApiFacade.getCapabilities$().subscribe());
    }

    private intializeReleaseTypes(): void {
        this.subscriptions.push(this.releaseTypesApi.getReleaseTypes$().subscribe());
    }
}
