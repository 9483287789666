import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { SHARED_ROUTES } from '@kno2/shared/util/configuration';

export const routes: Routes = [
    {
        path: SHARED_ROUTES.ROOT_ROUTE,
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule)
    },
    { path: '**', redirectTo: '' }
];

const config: ExtraOptions = {
    useHash: false
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
