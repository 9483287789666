import { NgModule } from '@angular/core';
import { SharedApiKno2fyMainModule } from '@kno2/shared/data-access/api-kno2fy-main';
import { AuthService } from '@kno2/shared/feature/auth';
import { SharedUtilConfigurationModule } from '@kno2/shared/util/configuration';
import { SharedUtilIntegrationsModule } from '@kno2/shared/util/integrations';
import { StoreModuleConfiguration } from './store.module';
import { AuthService as AuthIpcService, DesktopDataAccessApiDesktopModule } from '@kno2/desktop/data-access/api-desktop';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../interceptor/auth.interceptor';

@NgModule({
    imports: [
        SharedUtilConfigurationModule,
        SharedApiKno2fyMainModule,
        StoreModuleConfiguration,
        SharedUtilIntegrationsModule,
        DesktopDataAccessApiDesktopModule
    ],
    providers: [
        {
            provide: AuthService,
            useClass: AuthIpcService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {}
