import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedThemeModule } from '@kno2/shared/ui/theme';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './@core/core.module';
import { CommonModule } from '@angular/common';
import { Environment, APP_ORIGIN_NAME_TOKEN, NON_PHI_FAX_ORIGIN_TOKEN } from '@kno2/shared/util/configuration';
import { environment } from '../environments/environment';
import { InputMaskModule } from '@ngneat/input-mask';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        InputMaskModule,
        AppRoutingModule,
        SharedThemeModule.forRoot()
    ],
    providers: [
        {
            provide: Environment,
            useValue: new Environment(environment)
        },
        {
            provide: APP_ORIGIN_NAME_TOKEN,
            useValue: `${environment.brand.displayName}-Desktop`.replace(' ', '-')
        },
        {
            provide: NON_PHI_FAX_ORIGIN_TOKEN,
            useValue: `Desktop-${environment.brand.displayName}-Non-Patient`.replace(' ', '-')
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
